

.MainWrapper {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: auto;
}
.ScreensWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 60px;
}
.OutputWrapper {
  display: flex;
  flex-direction: row;
}

input,
button,
select {
  margin: 5px;
}

.form-fields-row form{
  display: flex;
  flex-wrap: wrap;
}

